import { inject, Injectable, signal } from '@angular/core';
import { ApiServiceBase } from '@api/abstracts';
import { StoreCommon } from '@api/endpoints/store-common/models';
import { DataService } from '@shared/services';
import { AuthService } from '@shared/services/auth/auth.service';
import { EMPTY, map, Observable, take } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class StoreCommonService extends ApiServiceBase<StoreCommon> {
  readonly #authService = inject(AuthService);
  readonly #dataService = inject(DataService);

  #storeCommon = signal<StoreCommon | undefined>(undefined);
  storeCommon = this.#storeCommon.asReadonly();

  constructor() {
    super('store/common');
  }

  storeCommonSaved(): Observable<boolean> {
    return this.#dataService.get$('storeCommon').pipe(
      tap((logged: unknown) => {
        this.#storeCommon.set(logged as StoreCommon);
      }),
      map((storeCommon) => !!storeCommon)
    );
  }

  getStoreCommon(): Observable<StoreCommon> {
    const storeId = this.#authService.storeLogged()?.storeId;
    if (storeId) {
      return this.read(String(storeId)).pipe(
        tap((storeCommon) => {
          this.#storeCommon.set(storeCommon);
          this.#dataService.set('storeCommon', storeCommon).pipe(take(1)).subscribe();
        })
      );
    } else {
      return EMPTY;
    }
  }
}
